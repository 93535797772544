import { TablePreferences } from '@veneer/core'

export const basePath = '/customers'
export const detailsPath = '/customers/details'
export const settingsPath = '/customers/settings'

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
) // eslint-disable-line

export const CustomerConstants = {
  name: 'name',
  customerName: 'CustomerName',
  country: 'country',
  ascending: 'ascending',
  pageSize: 25,
  currentPage: 1,
  rowConfig: 'rowConfig',
  selected: 'selected'
}

export const defaultpageSizeOptions = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 }
]

type ColumnIndexTypes = 'visible' | 'hidden' | 'none'

type userListColumnstype = {
  id: string
  label: string
  index?: ColumnIndexTypes
  required?: boolean
  sortable?: boolean
}
export const defaultOrderColumn = ['name', 'country']

export const customerListColumns: Array<userListColumnstype> = [
  {
    id: 'name',
    label: 'Customer Name',
    required: true
  },
  {
    id: 'country',
    label: 'Country/Region'
  },
  // Disable the domain column for now will enable once dependent tasks are done.
  // {
  //   id: 'domain',
  //   label: 'DOMAIN'
  // },
  {
    id: 'uid',
    label: 'uid',
    index: 'hidden'
  }
]

export const preferences: TablePreferences = {
  sortBy: {
    id: CustomerConstants.name,
    type: 'ascending'
  },
  width: [
    {
      columnId: 'name',
      width: 105
    },
    {
      columnId: 'country',
      width: 105
    },
    {
      columnId: 'domain',
      width: 105
    }
  ]
}

export const CountryListpreferences: TablePreferences = {
  sortBy: {
    id: 'countryName',
    type: 'ascending'
  },
  width: [
    {
      columnId: 'countryName',
      width: 105
    }
  ]
}

export const CountryListConstants = {
  countryName: 'countryName',
  ascending: 'ascending',
  pageSize: 25,
  currentPage: 1,
  rowConfig: 'rowConfig'
}

export const countryNameColumns: Array<userListColumnstype> = [
  {
    id: 'countryName',
    label: 'COUNTRY NAME'
  },
  {
    id: 'uid',

    label: 'uid',

    index: 'hidden'
  }
]

export const SelectAccountColumns: Array<userListColumnstype> = [
  { id: 'columnHeader', label: 'COLUMN HEADER' },
  { id: 'email', label: 'EMAIL' },
  { id: 'role', label: 'ROLE' },
  { id: 'uid', label: 'uid', index: 'hidden' }
]

export const selectAccountPreference: TablePreferences = {
  sortBy: {
    id: 'columnHeader',
    type: 'ascending'
  },
  width: [
    {
      columnId: 'columnHeader',
      width: 144
    },
    {
      columnId: 'email',
      width: 200
    },
    {
      columnId: 'role',
      width: 200
    }
  ]
}

export const SelectAccountConstants = {
  columnHeader: 'columnHeader',
  email: 'email',
  role: 'role',
  ascending: 'ascending',
  pageSize: 25,
  currentPage: 1,
  rowConfig: 'rowConfig'
}

export const LocaleStrings = {
  detailsOrgProfile: 'tooltip.detailsOrgProfile',
  detailsITAdmins: 'tooltip.detailsITAdmins',
  detailsDomain: 'tooltip.detailsDomain',
  detailsIDP: 'tooltip.detailsIDP'
}

export const WRITE_PERMISSION = [{ scope: 'ws-hp.com/id-config/config.WRITE' }]

export const IdpDomains = [
  { value: 'HP', label: 'HP' },
  { value: 'Google', label: 'Google' },
  { value: 'Azure', label: 'Azure' }
]
