import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Select } from '@veneer/core'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'

const ContextualFooter = ({
  numberOfSelectedItems,
  onCancel,
  openSettings,
  writePermForSettings,
  showSettingsButtonFlag
}) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  const [selectValues, setSelectValues] = useState<Array<string>>([])
  const [disableEdit, setDisableEdit] = useState<boolean>(true)
  const [disableContinue, setDisableContinue] = useState<boolean>(true)

  useEffect(() => {
    numberOfSelectedItems == 1 ? setDisableEdit(false) : setDisableEdit(true)
  }, [disableEdit, numberOfSelectedItems])

  useEffect(() => {
    selectValues.length === 0 || numberOfSelectedItems > 1
      ? setDisableContinue(true)
      : setDisableContinue(false)
  }, [selectValues, numberOfSelectedItems])

  const getContextualFooterOptions = () => {
    return [
      {
        label: t('customer-management.tableHeader.setIDP', 'Set IDP'),
        value: 'setIdp',
        disabled:
          numberOfSelectedItems > 1 ||
          writePermForSettings === false ||
          showSettingsButtonFlag === false
      }
    ]
  }

  const handleBtnAction = () => {
    switch (selectValues[0]) {
      case 'setIdp':
        openSettings(selectValues[0])
        break
    }
  }

  return (
    <>
      <div
        className="footer-contextual-menu"
        data-testid="footer-contextual-menu"
      >
        <div>
          <li>
            <div className="left-cancel-button-box">
              <Button
                id="cancelBtn"
                appearance="secondary"
                className="mr-20"
                onClick={() => onCancel()}
                data-testid="cancel-button"
              >
                {t('customer-management.footerContextualMenu.cancel', 'Cancel')}
              </Button>
            </div>
            <div className="items-selected-box">
              <label data-testid="items-label">
                {numberOfSelectedItems + ' '}
                {t(
                  'customer-management.tableHeader.itemsSelected',
                  'Items selected'
                )}
              </label>
            </div>
            <div className="customer-right-action-box">
              <div className="customer-right-action-flex-box">
                <div className="customer-right-action-select-box">
                  <Select
                    options={getContextualFooterOptions()}
                    label={t(
                      'customer-management.tableHeader.selectAction',
                      'selectAction'
                    )}
                    className="CustomerSelectBox"
                    data-testid="customer-select-footer-batch-operation"
                    id="customer-select-footer-batch-operation"
                    clearIcon={false}
                    placement={'top'}
                    value={selectValues}
                    onChange={({ value: v }) => {
                      setSelectValues([v.toString()])
                    }}
                  />
                </div>
                <div>
                  <Button
                    disabled={disableContinue}
                    data-testid="confirm-batch-button"
                    id="deleteBtn"
                    appearance="primary"
                    onClick={() => handleBtnAction()}
                  >
                    {t(
                      'customer-management.footerContextualMenu.continue',
                      'Continue'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </li>
        </div>
      </div>
    </>
  )
}

ContextualFooter.propTypes = {
  numberOfSelectedItems: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedItems: PropTypes.array
}

export default ContextualFooter
