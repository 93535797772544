import { TableI18n } from '@veneer/core'
import { useMemo } from 'react'
import useShellRootContext from '../contexts/ShellRoot/useShellRootContext'

const Retrievei18nItems = (): TableI18n => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  const retrieveValue = useMemo(() => {
    return {
      actionButton: '',
      cancelButton: t(
        'customer-management.footerContextualMenu.cancel',
        'Cancel'
      ),
      clearAllFilters: '',
      columnOptions: t(
        'customer-management.customers.columnOptionsTitle',
        'column Options'
      ),
      columnResizeTooltip: '',
      deselectAllItems: '',
      downButton: '',
      itemSelected: '',
      itemsSelected: t(
        'customer-management.tableHeader.itemsSelected',
        'Items selected'
      ),
      noItems: '',
      resetToDefault: t(
        'customer-management.customers.resetToDefault',
        'Reset to Default'
      ),
      saveButton: t('customer-management.footerContextualMenu.save', 'Save'),
      selectAllItems: '',
      selectAllPageItems: '',
      sortedAscending: '',
      sortedDescending: '',
      upButton: '',
      sortByAscending: '',
      sortByAscendingActive: '',
      sortByDescending: '',
      sortByDescendingActive: ''
    }
  }, [t])
  return retrieveValue
}

export default Retrievei18nItems
