import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container } from './styles'
import CustomerDetails from '../ManageCustomers/CustomerDetails'
import { ThirdPartyIDPSettings } from '../../../src/Shared-Components/ThirdPartyIDPSettings/index'
import { addDataTestID, setProps } from '../../utils/commonMethods'
import { CustomerList } from '../../interfaces/customerDataInterface'
import { getCustomerDetailsList, getIsTechnician } from '../../utils/api'
import './manageCustomers.scss'
import {
  WRITE_PERMISSION,
  basePath,
  detailsPath,
  settingsPath
} from '../../../src/utils/constants'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import { MainContent } from '../../../src/components/ManageCustomers/MainContent/index'
import { GlobalHeader } from '../../Shared-Components/GlobalHeader/index'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'

const ManageCustomers = (props) => {
  setProps(props)

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation || props?.mockNavigation
  const events = interfaces?.v1?.events || props?.mockEvents
  const breadcrumbs = interfaces?.v1?.breadcrumbs
  const featureFlags = interfaces?.v1?.featureFlags || props?.mockFeatureFlags
  const accessControl =
    interfaces?.v1?.accessControl || props?.mockAccessControl
  const orgSelector = interfaces?.v1?.orgSelector
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider
  const permissionSet = props?.properties?.permissionSet
  const makeAdmin = props?.makeAdmin || undefined
  const mockProp = props?.mockProp || undefined

  useEffect(() => {
    navigation.push(basePath)
  }, [navigation])

  const [customers, setCustomers] = useState<Array<CustomerList>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showCustomerDetails, setShowCustomerDetails] = useState<boolean>(false)
  const [showCustomerSettings, setShowCustomerSettings] =
    useState<boolean>(false)
  const [viewCustomer, setViewCustomer] = useState<CustomerList>()
  const [isTechnician, setIsTechnician] = useState<boolean>(false)
  const [writePermForSettings, setWritePermForSettings] =
    useState<boolean>(false)
  const [showSettingsButtonFlag, setShowSettingsButtonFlag] = useState<boolean>(
    mockProp ? true : false
  )
  const [hasError, setHasError] = useState<boolean>(false)
  const [pageTitle, setPageTitle] = useState<string>(
    `${t('customer-management.title', 'Customers')}`
  )

  const getFeatureFlag = useCallback(async () => {
    const client = await featureFlags.getClient('ecp')
    const featureFlag = await client.getFeatureFlag({
      key: 'ecp-3id',
      defaultValue: false
    })
    setShowSettingsButtonFlag(featureFlag as boolean)
    return featureFlag
  }, [featureFlags])

  useEffect(() => {
    navigation.listen((location) => {
      if (location.pathname === detailsPath) {
        setPageTitle(' ')
      } else if (location.pathname === settingsPath) {
        setPageTitle(
          `${t('customer-management.actionButtons.settings', 'Settings')}`
        )
      } else {
        setPageTitle(`${t('customer-management.title', 'Customers')}`)
      }
    })
  }, [navigation, t])

  useEffect(() => {
    getFeatureFlag()
  }, [getFeatureFlag, showSettingsButtonFlag])

  useEffect(() => {
    accessControl
      .checkScopes(WRITE_PERMISSION)
      .then((result) => setWritePermForSettings(result))
  })

  const handleDetailsBreadcrumbs = useCallback(() => {
    navigation?.push(detailsPath)
    breadcrumbs?.add({
      key: 'customer-details',
      text: 'Details',
      translationKey: undefined,
      url: undefined
    })
  }, [breadcrumbs, navigation])

  useEffect(() => {
    if (showCustomerDetails) {
      handleDetailsBreadcrumbs()
    }
  }, [handleDetailsBreadcrumbs, showCustomerDetails])

  const handleSettingsBreadcrumbs = useCallback(() => {
    navigation?.push(settingsPath)
    breadcrumbs?.remove('customer-details')
    breadcrumbs?.add({
      key: 'customer-details',
      text: `${t('customer-management.details', 'Details')}`,
      translationKey: undefined,
      url: detailsPath
    })
    breadcrumbs?.add({
      key: 'customer-settings',
      text:
        t('customer-management.actionButtons.settings', 'Settings') +
        ':' +
        t('customer-management.customerSettings.iDP', 'IDP'),
      translationKey: undefined,
      url: undefined
    })
  }, [breadcrumbs, navigation, t])

  useEffect(() => {
    if (showCustomerSettings) {
      handleSettingsBreadcrumbs()
    }
  }, [handleSettingsBreadcrumbs, showCustomerSettings])

  const getTechnician = useCallback(async () => {
    const isTech = await getIsTechnician(authStack, authProvider, permissionSet)
    setIsTechnician(isTech)
  }, [authProvider, authStack, permissionSet])

  const page = useRef(0)
  const totalPages = useRef(0)
  const customersTemp = useRef([])

  const retriveCustomers = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getCustomerDetailsList(
        page.current,
        orgSelector,
        authStack,
        authProvider
      )
      totalPages.current = response?.totalPages
      // eslint-disable-next-line no-unsafe-optional-chaining
      customersTemp.current.push(...response?.content)
      if (page.current < totalPages.current - 1) {
        page.current = page.current + 1
        retriveCustomers()
      } else {
        const sortedCustomers = customersTemp.current.sort((a, b) =>
          a.accountName.localeCompare(b.accountName, undefined, {
            numeric: true
          })
        )
        setCustomers(sortedCustomers)
        customersTemp.current = []
        totalPages.current = 0
        page.current = 0
        setIsLoading(false)
        setHasError(false)
      }
    } catch (error) {
      console.log('error > retrieveCustomers', error)
      setIsLoading(false)
      setHasError(true)
    }
  }, [authProvider, authStack, orgSelector])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', retriveCustomers)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', retriveCustomers)
    }
  }, [events, retriveCustomers])

  useEffect(() => {
    makeAdmin ? setIsTechnician(false) : getTechnician()
    retriveCustomers()
    addDataTestID()
    isTechnician ? null : null
  }, [getTechnician, isTechnician, makeAdmin, retriveCustomers])

  useEffect(() => {
    addDataTestID()
  })

  const RenderMainContent = (
    <div className="customers-main-content">
      <MainContent
        isLoading={isLoading}
        writePermForSettings={writePermForSettings}
        showSettingsButtonFlag={showSettingsButtonFlag}
        setShowCustomerDetails={(value: boolean) =>
          setShowCustomerDetails(value)
        }
        setShowCustomerSettings={(value: boolean) =>
          setShowCustomerSettings(value)
        }
        setViewCustomer={(value: CustomerList) => setViewCustomer(value)}
        setIsLoading={(value: boolean) => setIsLoading(value)}
        customers={customers}
        retriveCustomers={retriveCustomers}
        hasError={hasError}
      />
    </div>
  )

  const RenderCustomerDetails = (
    <div className="customers-details">
      <CustomerDetails
        selectedCustomer={viewCustomer}
        openSettings={() => {
          setShowCustomerDetails(false), setShowCustomerSettings(true)
        }}
        writePermForSettings={writePermForSettings}
        showSettingsButtonFlag={showSettingsButtonFlag}
        setShowCustomerSettings={(value: boolean) =>
          setShowCustomerSettings(value)
        }
      />
    </div>
  )

  const RenderCustomerSettings = (
    <div className="customers-settings">
      <ThirdPartyIDPSettings
        mfe={'customers'}
        basePath={basePath}
        navigation={navigation}
        nodeId={viewCustomer?.nodeId || viewCustomer?.resourceId}
      />
    </div>
  )

  const getContent = () => {
    return (
      <>
        <Router>
          <Route
            render={() => (
              <Switch>
                <Route
                  exact
                  path="*/customers"
                  render={() => RenderMainContent}
                />
                <Route
                  path="*/customers/details"
                  component={() => RenderCustomerDetails}
                />
                <Route
                  path="*/customers/settings"
                  component={() => RenderCustomerSettings}
                />
              </Switch>
            )}
          />
        </Router>
      </>
    )
  }

  return (
    <>
      <GlobalHeader title={pageTitle} />
      <Container
        className={
          showCustomerSettings ? 'header-settings' : 'header-customers'
        }
      >
        {getContent()}
      </Container>
    </>
  )
}

export default ManageCustomers
