import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #000000;
  background: #f8f8f8;
  font-family: ${primitives.typography.family0} !important;
  h4 {
    font-family: ${primitives.typography.family0};
    font-size: ${primitives.typography.size6};
    line-height: ${primitives.typography.lineHeight6};
  }
  div [dir='ltr'] {
    float: left;
    width: 20vw;
    padding: 24px 0px 0px 24px;
  }
  div [dir='ltr'] > div:first-child > div:first-child {
    font-family: ${primitives.typography.family0} !important;
    font-size: 14px !important;
    line-height: ${primitives.typography.lineHeight3} !important;
  }
  .footer-contextual-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: block;
    margin: 0 !important;
    height: 60px;
    right: 0;
    z-index: 3;
    animation: 1s ease 0s 1 normal none running animatebottom;
    li {
      -webkit-box-align: center !important;
      align-items: center;
      border-color: transparent;
      border-radius: 0px;
      border-style: solid;
      border-width: 0px 1px;
      box-sizing: border-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: ${primitives.layout.size3}px ${primitives.layout.size6}px;
      border-left-width: 261px;
      position: relative;
      width: 100%;
      background: #ebf9ff;
    }
  }
  .left-cancel-button-box {
    display: inline-flex;
    padding-right: ${primitives.layout.size3}px;
    button {
      height: 36px;
      width: 112px;
      max-width: 112px;
      min-width: 112px;
      padding: ${primitives.layout.size2}px 34px ${primitives.layout.size2}px
        34px;
      border-radius: ${primitives.layout.cornerRadius2}px;
    }
  }
  .items-selected-box {
    display: inline-flex;
    flex: 1 1 100%;
    order: 1;
    font-family: ${primitives.typography.family0};
    font-size: ${primitives.typography.size2};
    line-height: ${primitives.typography.lineHeight3} !important;
    vertical-align: middle;
  }
  .customer-right-action-box {
    display: inline-flex;
    flex: 0 0 auto;
    order: 2;
    height: 36px;
    .CustomerSelectBox {
      line-height: ${primitives.typography.lineHeight2} !important;
      div {
        height: 34px;
      }
    }
    #deleteBtn {
      border-radius: ${primitives.layout.cornerRadius2}px;
      height: 36px;
      width: 112px;
      max-width: 112px;
      min-width: 112px;
    }
    #customer-select-footer-batch-operation-label {
      margin-top: -2px;
    }
    #customer-select-footer-batch-operation {
      padding-top: 27px;
      span {
        font-size: 16px;
        line-height: ${primitives.typography.lineHeight2} !important;
      }
    }
  }
  .customer-right-action-flex-box {
    display: flex;
    div {
      padding-right: 10px;
      div > div {
        padding-right: 16px;
        div + input + span {
          padding-right: 0px;
          div.vn-select__open-button {
            padding-right: 0px;
            height: 24px;
          }
        }
      }
    }
  }
  .customer-right-action-select-box {
    min-width: 224px;
    max-width: 320px;
    height: 36px;
  }
  #filter-side-bar-div {
    margin-bottom: 0px !important;
  }
  ul[role='listbox'] {
    li {
      line-height: ${primitives.typography.lineHeight2} !important;
    }
  }
  .noCustomerBlock {
    padding: ${primitives.layout.size6}px 0;
    svg {
      height: 33.33px;
      width: 33.33px;
      color: ${primitives.color.gray9};
      margin-bottom: 5px;
    }
  }
  div.customers-main-content {
    padding: ${primitives.layout.size4}px ${primitives.layout.size6}px
      ${primitives.layout.size6}px;
  }
  div.customers-details,
  div.customers-settings {
    padding: ${primitives.layout.size6}px;
  }
`
export const NoCustomers = styled.div`
  color: ${primitives.color.gray9};
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
`
export const InviteCustomer = styled.div`
  color: ${primitives.color.gray9};
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
`
export const VeneerTable = styled.div`
  thead > tr,
  tbody > tr {
    line-height: ${primitives.typography.lineHeight2};
  }
  > div > div:first-child {
    button[aria-label='Column Options'] {
      height: 36px !important;
      width: 36px !important;
    }
  }
  button[aria-label='Select page size'] {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 5px;
  }
  span.vn-checkbox__span {
    width: 20px !important;
    height: 20px !important;
  }
  nav > ul > li > button {
    line-height: ${primitives.typography.lineHeight2};
  }
  thead > tr > th {
    padding: ${primitives.layout.size2}px ${primitives.layout.size2}px
      ${primitives.layout.size2}px ${primitives.layout.size4}px;
  }
  tbody > tr > td {
    padding: ${primitives.layout.size2}px ${primitives.layout.size2}px
      ${primitives.layout.size2}px ${primitives.layout.size4}px;
    height: 44px;
  }
  div:nth-child(2) {
    line-height: ${primitives.typography.lineHeight2};
  }
`
export const ViewDetailsMaskStyle = styled.div`
  position: absolute;
  width: 92.8%;
  z-index: 1;
  margin-top: -14px;
  cursor: pointer;
  height: 85%;
`
export const SearchContent = styled.div`
  padding-top: 8px;
  padding-bottom: 12px !important;
  margin-bottom: -48px !important;
  display: flex;
  width: 100%;
  label {
    line-height: ${primitives.typography.lineHeight1};
  }
  #customers-search-form {
    flex-grow: 9;
    .customers-searchBox-input_container {
      float: left;
      .vn-input {
        height: 36px;
        min-height: 36px;
        max-height: 36px;
        width: 270px;
        border-radius: ${primitives.layout.cornerRadius2}px !important;
      }
      #customers-table-name-filter {
        padding: 6px 12px;
        height: ${primitives.typography.lineHeight6};
      }
    }
    .customers-filter-button {
      display: none;
      height: ${primitives.typography.lineHeight6};
      span:first-child {
        margin-right: 4px !important;
      }
      width: 91px;
      padding: 11px 0px;
    }
  }
  .btn-container {
    flex-grow: 0;
    .create-button {
      height: ${primitives.typography.lineHeight6};
      width: 107px;
      min-width: 0px;
      border-radius: ${primitives.layout.cornerRadius2}px;
    }
    .settings-button {
      height: ${primitives.typography.lineHeight6};
      width: ${primitives.typography.lineHeight6};
      margin-left: 12px;
    }
  }
`
export const ReloadSection = styled.div`
  float: right;
  margin-top: -76px;
  margin-bottom: 0 !important;
  button {
    margin-right: ${primitives.layout.size1}px;
  }
`
export const ReloadBoxStyle = styled.div`
  text-align: right;
  margin-bottom: ${primitives.layout.size6}px !important;
  padding-right: ${primitives.layout.size1}px;
  button {
    color: #027aae;
    margin-right: ${primitives.layout.size1}px;
  }
`

export const RelaodCaption = styled.span`
  width: 227px !important;
  height: 20px !important;
  color: ${primitives.color.gray9};
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2} !important;
`

export const VeneerSpace2 = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: ${primitives.layout.size2}px;
`

export const CustomNoITemsContainer = styled.div`
  > div:first-child > div:first-child {
    margin-bottom: ${primitives.layout.size2}px;
  }
`
