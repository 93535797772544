import { CustomerList } from 'src/interfaces/customerDataInterface'

let localprops = {
  language: 'en',
  country: 'US',
  mock: undefined
}

export const setProps = (props) => {
  localprops = props
}

export const getProps = () => {
  return localprops
}

export const getDateandTime = () => {
  const today = new Date()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const month = months[today.getMonth()].substring(0, 3)
  const locale = 'en'
  const dateAndTime = `${month}${'\xa0'}${today.getDate()},
                ${today.toLocaleDateString(locale, {
                  year: 'numeric'
                })}${'\xa0'}${today.toLocaleTimeString(locale, {
                  hour: '2-digit',
                  hour12: true,
                  minute: '2-digit',
                  second: '2-digit'
                })}`
  return dateAndTime
}
export const filter = (
  userListData: Array<CustomerList>,
  searchItem: string,
  searchItems: string[]
) => {
  if (searchItem.length === 0 && searchItems.length === 0) {
    return userListData
  }
  const filterdata = userListData.filter((account) => {
    if (searchItem.length != 0) {
      return (
        account?.accountName
          ?.toLowerCase()
          .includes(searchItem.toLowerCase()) ||
        account?.regionId?.toLowerCase().includes(searchItem.toLowerCase())
      )
    } else {
      return account
    }
  })

  return filterdata
}
export const sort = <Type>(
  data: Array<Type>,
  orderBy: string,
  orderType: string
) => {
  return data.sort((obj1, obj2) => {
    const value =
      orderType === 'descending'
        ? descendingComparator(obj1, obj2, orderBy)
        : -descendingComparator(obj1, obj2, orderBy)

    return value
  })
}

export const descendingComparator = <Type>(
  obj1: Type,
  obj2: Type,
  orderBy: string
) => {
  const customerName1 = obj1[orderBy] && obj1[orderBy].toString().toUpperCase()
  const customerName2 = obj2[orderBy] && obj2[orderBy].toString().toUpperCase()
  if (new Intl.Collator().compare(customerName1, customerName2)) {
    return -1
  }
  if (new Intl.Collator().compare(customerName2, customerName1)) {
    return 1
  }
  return 0
}

export const selectRow = (
  dataArray: CustomerList[],
  checked: boolean,
  id?: number
) => {
  const updatedDataArray = [...dataArray]
  const index = updatedDataArray.findIndex((row) => row.uid === id)
  updatedDataArray[index].rowConfig.selected = checked
  return updatedDataArray
}

export const handleMultipleUsers = (
  dataArray: CustomerList[],
  checked: boolean
) => {
  return dataArray.map((row) => {
    const newRow = row
    newRow.rowConfig.selected = checked
    return newRow
  })
}

export const selectionState = (dataArray: CustomerList[]) => {
  const total = dataArray.length
  const selected = dataArray.filter((row) => row.rowConfig.selected).length

  switch (selected) {
    case 0:
      return 'none'
    case total:
      return 'all'
    default:
      return 'indeterminated'
  }
}

export const addDataTestID = () => {
  const createContainer = document.querySelectorAll(
    '.header-create-customers'
  )[0]
  const editContainer = document.querySelectorAll('.header-edit-customers')[0]
  const settingsContainer = document.querySelectorAll('.header-settings')[0]
  const customersContainer = document.querySelectorAll('.header-customers')[0]
  if (createContainer) {
    const div = createContainer.querySelectorAll('div')[3]
    const h4 = createContainer.querySelectorAll('h4')[0]
    h4?.setAttribute('data-testid', 'header-create-customers')
    div?.setAttribute('data-testid', 'header-create-customers-description')
  }
  if (editContainer) {
    const div = editContainer.querySelectorAll('div')[3]
    const h4 = editContainer.querySelectorAll('h4')[0]
    h4?.setAttribute('data-testid', 'header-edit-customers')
    div?.setAttribute('data-testid', 'header-edit-customers-description')
  }
  if (settingsContainer) {
    const div = settingsContainer.querySelectorAll('div')[3]
    const h4 = settingsContainer.querySelectorAll('h4')[0]
    h4?.setAttribute('data-testid', 'header-settings')
    div?.setAttribute('data-testid', 'header-settings-description')
  }
  if (customersContainer) {
    const div = customersContainer.querySelectorAll('div')[3]
    const h4 = customersContainer.querySelectorAll('h4')[0]
    h4?.setAttribute('data-testid', 'header-customers')
    div?.setAttribute('data-testid', 'header-customers-description')
  }
}

export const jsonParse = (text: string, defResult = []) => {
  try {
    return text ? JSON.parse(text) : defResult
  } catch (SyntaxError) {
    return defResult
  }
}
