import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SideBar, Checkbox, List, ListItem, IconX } from '@veneer/core'
import { IdpDomains } from '../../utils/constants'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import * as S from './FilterSidebarStyles'

const FilterSidebar = ({ onclose, performFilter, appliedFilter }) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  const [searchValue, setSearchValue] = useState<string>('')
  const [showInvalidSearchMsg, setShowInvalidSearchMsg] =
    useState<boolean>(false)
  const [statusLabels, setStatusLabels] = useState([])
  const handleSearchValue = (value: string) => {
    setSearchValue(value)
  }

  const handleClearSearch = () => {
    setSearchValue('')
    setStatusLabels(IdpDomains)
    setShowInvalidSearchMsg(false)
  }

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const filterStatusdata = IdpDomains.filter((user) =>
      user.label.toLowerCase().includes(searchValue.toLowerCase())
    )
    setStatusLabels(filterStatusdata)
  }

  const handleFilterCheck = (e) => {
    e.target.checked
      ? performFilter(e.target.value, 'push')
      : performFilter(e.target.value, 'pop')
  }

  useEffect(() => {
    setStatusLabels(IdpDomains)
  }, [])

  const handleCheckboxChange = (e: {
    target: EventTarget & HTMLInputElement
  }) => {
    handleFilterCheck(e), fillOrRemoveFromArr(e.target)
  }

  const [arr, setArr] = useState<Array<EventTarget & HTMLInputElement>>([])

  const fillOrRemoveFromArr = (target: EventTarget & HTMLInputElement) => {
    target.checked
      ? setArr([...arr, target])
      : setArr(arr.filter((item) => item !== target))
  }

  const handleAllUncheck = (): void => {
    for (let i = 0; i < arr.length; i++) {
      arr[i].checked = false
      setArr([])
    }
    return null
  }

  const mainContent = (
    <>
      <S.FilterSidebarPrimaryTitle data-testid="filter-sidebar-primary-title">
        Filter
      </S.FilterSidebarPrimaryTitle>
      <S.FilterSidebarPrimaryDesc data-testid="filter-sidebar-description">
        Select filters to view in the list.
      </S.FilterSidebarPrimaryDesc>
      <S.SearchFiltersDiv
        data-testid="search-filters-div"
        id="search-filters-div"
        onSubmit={handleSearch}
      >
        <S.StyledSearch
          data-testid="search-filters-box"
          id="search-filters-box"
          className="marginR11 floatL"
          placeholder={`Search filters`}
          onChange={handleSearchValue}
          onClear={handleClearSearch}
          content={undefined}
          nonce={undefined}
          rel={undefined}
          rev={undefined}
        />
      </S.SearchFiltersDiv>
      <S.FilterHolder>
        <S.FilterList id="builtinList">
          <>
            <S.FilterLabel>Built-in</S.FilterLabel>
            <S.Divider />
          </>
          <List>
            {IdpDomains &&
              statusLabels.map((statusLabel) => (
                <ListItem
                  key={statusLabel.label}
                  data-testid="filter-block-2-item-1"
                  id="filter-block-2-item-1"
                  leadingArea={
                    <Checkbox
                      data-testid="filter-block-2-checkbox-1"
                      id={statusLabel.label}
                      className="lablesCheckBox"
                      name="lablesCheckBox"
                      label={statusLabel.value}
                      value={statusLabel.value.toLowerCase()}
                      onChange={handleCheckboxChange}
                      checked={
                        appliedFilter.includes(statusLabel.value.toLowerCase())
                          ? true
                          : arr.includes(
                                arr.filter(
                                  (item) => item.id === statusLabel.label
                                )[0]
                              )
                            ? true
                            : false
                      }
                    />
                  }
                ></ListItem>
              ))}
            {showInvalidSearchMsg && (
              <div
                data-testid="not-available-span"
                className="not-available-span"
              >
                {`Searched filter not available`}
              </div>
            )}
          </List>
        </S.FilterList>
        <S.MarginBottom60 />
      </S.FilterHolder>

      {appliedFilter.length ? (
        <S.PrimaryFooter data-testid="filtersidebar-primary-footer">
          <S.SelectedItemsText data-testid="filtersidebar-selecteditems-text">
            {appliedFilter.length}{' '}
            {t(
              'customer-management.tableHeader.itemsSelected',
              'Items Selected'
            )}
          </S.SelectedItemsText>
          <S.ClearAllButton
            data-testid="filtersidebar-clearall-button"
            appearance="tertiary"
            leadingIcon={<IconX />}
            onClick={() => (handleAllUncheck(), performFilter('', 'clearall'))}
          >
            Clear all
          </S.ClearAllButton>{' '}
        </S.PrimaryFooter>
      ) : null}
    </>
  )

  const getContent = () => {
    return <>{mainContent}</>
  }

  return (
    <S.FilterSidebarContainer>
      <SideBar
        data-testid="filter-sidebar"
        id="filter-sidebar"
        behavior="overlay"
        position="end"
        disableBackdropClick
        size={302}
        show={true}
        content={getContent()}
        onClose={() => onclose()}
      />
    </S.FilterSidebarContainer>
  )
}

FilterSidebar.propTypes = {
  onclose: PropTypes.func.isRequired
}

export default FilterSidebar
