import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Button, TextBox, Search, Select, Modal } from '@veneer/core'

export const FilterSidebarContainer = styled.div`
  div[aria-label='Backdrop'] {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
  div[id='filter-sidebar'] {
    display: flex;
    flex-direction: column;
    padding: ${primitives.layout.size6}px;
  }
`
export const FilterSidebarPrimaryTitle = styled.p`
  font-weight: bold;
  font-size: ${primitives.typography.size5};
  line-height: ${primitives.typography.lineHeight5};
  margin-bottom: 9px;
`
export const FilterSidebarPrimaryDesc = styled.p`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
  margin-bottom: ${primitives.layout.size4}px;
`
export const SearchFiltersDiv = styled.form`
  width: 302px;
  margin-bottom: 35px;
`
export const CreateFiltersButton = styled(Button)`
  width: ${primitives.typography.lineHeight6};
  height: ${primitives.typography.lineHeight6};
  border-radius: ${primitives.layout.cornerRadius2}px !important;
  float: right;
`
export const StyledSearch = styled(Search)`
  width: 255px !important;
  height: ${primitives.typography.lineHeight6};
  margin-right: 11px;
  float: left;
  .vn-input {
    height: ${primitives.typography.lineHeight6};
    border-radius: ${primitives.layout.cornerRadius2}px;
  }
  label:first-child {
    width: 90px;
    line-height: ${primitives.typography.lineHeight1};
  }
  input:first-child {
    padding: ${primitives.layout.size2}px 0 ${primitives.layout.size2}px
      ${primitives.layout.size3}px;
    line-height: ${primitives.layout.size5}px !important;
  }
`
export const FilterHolder = styled.div`
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  overflow: auto;
  margin-right: -${primitives.layout.size6}px;
  #favouriteList,
  #builtinList,
  #customList {
    ul {
      li {
        border: 0;
        height: ${primitives.typography.lineHeight7};
        padding: 0 ${primitives.layout.size2}px;
        span {
          font-family: ${primitives.typography.family0};
          font-size: ${primitives.typography.size2};
          line-height: ${primitives.typography.lineHeight2};
        }
        button {
          padding: 0;
        }
        button:first-child {
          margin-right: ${primitives.layout.size3}px;
        }
      }
      li:hover {
        background-color: #f4f4f4;
      }
    }
  }
  #builtinList,
  #customList {
    ul {
      li {
        .hidden-child {
          visibility: hidden;
        }
      }
      li:hover {
        .hidden-child {
          visibility: visible;
        }
      }
    }
  }
  ul[role='listbox'] {
    li:hover {
      background-color: #035c84 !important;
    }
  }
`
export const FilterList = styled.div`
  width: 302px;
  margin-bottom: 10px;
`
export const CriteriaHolder = styled.div`
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  overflow: auto;
  margin-right: -${primitives.layout.size6}px;
  display: flex;
  flex-direction: column;
  .margin-60 {
    margin-bottom: 60px;
  }
`
export const CriteriaBlock = styled.div`
  width: 302px;
  margin-bottom: ${primitives.layout.size6}px;
  .labelBlock {
    width: 302px;
    height: ${primitives.typography.lineHeight3};
    margin: 0 0 ${primitives.layout.size4}px 0;
  }
  .label {
    float: left;
    width: 60px;
    margin: 0 10px 0 0;
    font-family: ${primitives.typography.family0};
    font-size: ${primitives.typography.size2};
  }
  .divider {
    float: right;
    width: 232px;
    margin-top: 1px;
    hr {
      border-top: 1px solid rgba(33, 33, 33, 0.1);
      margin: ${primitives.layout.size2}px 0;
    }
  }
  .deleteBtn {
    float: right;
    max-width: 67px;
    min-width: 67px;
    padding: 5px 0;
    .label {
      font-family: ${primitives.typography.family0};
      font-size: ${primitives.typography.size2};
      line-height: ${primitives.typography.lineHeight2};
      margin: 0 5px;
    }
  }
  .addCriteriaButton {
    width: 162px;
    padding: 5px 0;
    span:first-child + span {
      font-family: ${primitives.typography.family0};
      font-size: ${primitives.typography.size2};
      line-height: ${primitives.typography.lineHeight2};
    }
  }
`
export const RadioButtonsBlock = styled.div`
  width: 302px;
  height: ${primitives.typography.lineHeight3};
  margin: 0 0 ${primitives.layout.size6}px 0;
  vertical-align: middle;
  .divider {
    width: 84px;
    border-top: 1px solid rgba(33, 33, 33, 0.1);
  }
  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .marginL10 {
    margin: ${primitives.layout.size2}px 0 ${primitives.layout.size2}px 10px;
  }
  .marginR10 {
    margin: ${primitives.layout.size2}px 10px ${primitives.layout.size2}px 0;
  }
  .radioButtonsAndOr {
    float: left;
    margin: 0;
    height: ${primitives.typography.lineHeight2};
    label {
      span:first-child {
        width: 20px;
        height: ${primitives.typography.lineHeight2};
        margin: 0 ${primitives.layout.size2}px 0 0;
      }
      span:first-child + span {
        font-family: ${primitives.typography.family0};
        font-size: ${primitives.typography.size2};
        line-height: ${primitives.typography.lineHeight2};
      }
    }
    label:first-child {
      width: 54px;
      margin: 0 10px 0 0;
    }
    label:first-child + label {
      width: 44px;
    }
  }
`
export const FilterLabel = styled.p`
  float: left;
  width: 54px;
  margin: 0 10px 0 0;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.layout.size5}px;
`
export const Divider = styled.hr`
  float: right;
  width: 236px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  margin: 10px 0;
`
export const MarginBottom60 = styled.div`
  width: 100%;
  margin-bottom: 60px;
`
export const PrimaryFooter = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 307px;
  height: 49px;
  padding: 0 24px 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
`
export const SelectedItemsText = styled.p`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
  text-align: left;
`
export const ClearAllButton = styled(Button)`
  max-width: 83px;
  min-width: 83px;
  height: ${primitives.typography.lineHeight6};
  &:hover {
    background-color: transparent !important;
  }
`
export const NavButton = styled(Button)`
  background-color: transparent !important;
  color: ${primitives.color.gray12} !important;
  width: 140px;
  height: ${primitives.typography.lineHeight4};
  padding: 0 !important;
  margin: 0 0 ${primitives.layout.size4}px -${primitives.layout.size6}px !important;
  font-family: ${primitives.typography.family0} !important;
  font-size: ${primitives.typography.size5} !important;
  line-height: ${primitives.typography.lineHeight4} !important;
  &:hover {
    background-color: transparent;
    color: ${primitives.color.gray12};
  }
`
export const CreateFiltersTextBox = styled(TextBox)`
  margin: 0 0 ${primitives.layout.size6}px 0;
  .vn-input {
    border-radius: ${primitives.layout.size2}px;
    height: ${primitives.typography.lineHeight6};
    input {
      padding: ${primitives.layout.size2}px ${primitives.layout.size3}px;
      font-family: ${primitives.typography.family0};
      font-size: ${primitives.typography.size2};
      line-height: ${primitives.typography.lineHeight2};
    }
  }
`
export const AddCriteriaContainer = styled.div`
  text-align: center;
`
export const AddCriteriaButton = styled(Button)`
  height: 36px;
  border-radius: 8px !important;
  width: 162px;
  padding: 5px 0 !important;
  span:first-child + span {
    font-family: ${primitives.typography.family0};
    font-size: 16px;
    line-height: 20px;
  }
`
export const ActionButtonsGroup = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 331px;
  height: 60px;
  padding: 0 ${primitives.layout.size6}px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  background-color: white;
  button {
    max-width: 83px;
    min-width: 83px;
    height: ${primitives.typography.lineHeight6};
    border-radius: ${primitives.layout.size2}px;
  }
  .marginR10 {
    margin: 0 10px 0 0;
  }
`
export const StyledSelect = styled(Select)`
  margin: 0 0 ${primitives.layout.size4}px 0;
  label {
    font-size: 10px;
    line-height: ${primitives.typography.lineHeight0};
  }
  label + div {
    height: 36px;
    border-radius: ${primitives.layout.size2}px;
  }
  input {
    line-height: ${primitives.typography.lineHeight2};
  }
`
export const StyledTextbox = styled(TextBox)`
  margin: 0 0 ${primitives.layout.size4}px 0;
  label {
    font-size: 10px;
    line-height: ${primitives.typography.lineHeight0};
  }
  label + div {
    height: 36px;
    border-radius: ${primitives.layout.size2}px;
  }
  input {
    line-height: ${primitives.typography.lineHeight2};
  }
`
export const RemoveModal = styled(Modal)`
  .vn-modal--content {
    min-width: 480px;
    max-width: 480px;
    height: 264px;
    padding: 31px 39px;
  }
  #delete-filter-modal-modalBox {
    margin: 0 0 $space-4 0;
    height: 36px;
    div:first-child {
      height: 36px;
    }
    svg {
      max-width: 36px !important;
      min-width: 36px !important;
      max-height: 36px !important;
      min-height: 36px !important;
      fill: #d06702 !important;
      margin-right: ${primitives.layout.size2}px;
      transform: scale(1);
      vertical-align: middle;
    }
    label {
      font-family: ${primitives.typography.family0};
      font-size: ${primitives.typography.size7};
      line-height: ${primitives.typography.lineHeight6};
      vertical-align: middle !important;
    }
  }
  .modal-content {
    margin-top: ${primitives.layout.size5}px;
  }
  .deleteFilterModalSubcontent {
    margin: 32px 0 0 0;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-family: ${primitives.typography.family0};
  }
  .vn-modal--footer {
    padding: 0;
    margin: 32px 0 0 0;
    button {
      min-width: 83px;
      max-width: 83px;
      height: 36px;
      border-radius: ${primitives.layout.cornerRadius2}px;
    }
  }
  .vn-button-group--responsive {
    float: right;
  }
`
