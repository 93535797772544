/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  RelationshipsClient,
  TenantMgtSvcClient,
  UserMgtSvcClientv3,
  AccountMgtSvcClientV3,
  DeviceCacheApiClient,
  UserTenantMgtSvcClient
} from '@jarvis/web-stratus-client'

// Customer List
export const getCustomerDetailsList = async (
  page,
  orgSelector,
  authStack,
  authProvider
) => {
  const tenantID = await orgSelector.getOrgTenantId()
  const options = {
    nodeId: tenantID,
    page: page,
    size: 1000
  }
  const customerMgtSvcClient = new RelationshipsClient(
    authStack,
    authProvider.createOrgedAuthProvider?.()
  )
  const response =
    await customerMgtSvcClient.getNodewithAdministersCrossUses(options)
  return response?.data
}

// Customer Details
export const getCustomerDetails = async (
  value,
  authStack,
  authProvider
): Promise<any> => {
  if (value) {
    const tenantMgtSvcClient = new TenantMgtSvcClient(
      authStack,
      authProvider.createOrgedAuthProvider?.()
    )
    const response = await tenantMgtSvcClient.getTenantDetails(value)
    return response?.data
  }
}

// Solutions count on to Details page
export const getCustomerSolutions = async (
  authStack,
  authProvider,
  tenantResourceId
) => {
  const userMgtClient = new UserMgtSvcClientv3(
    authStack,
    authProvider.createOrgedAuthProvider?.()
  )
  const activeUser = await userMgtClient.getCurrentActiveUser()
  const client = new AccountMgtSvcClientV3(
    authStack,
    authProvider.createOrgedAuthProvider?.()
  )
  const response = await client.getProgramInfos(
    tenantResourceId,
    activeUser?.data?.resourceId
  )
  const count = response?.data?.programInfoMap?.UCDE?.entitlementList
  return count
}

// Devices Count on to Details page
export const getCustomerDevices = async (
  custResourseId,
  authStack,
  authProvider
): Promise<any> => {
  const client = new DeviceCacheApiClient(
    authStack,
    authProvider.createOrgedAuthProvider?.(custResourseId)
  )
  const response = await client.count({
    url: '/devices',
    params: {
      accountId: custResourseId
    }
  })
  const count = parseInt(response?.headers?.['x-total-count'])
  return count > 0 ? count : 0
}

// IT admin details
export const getCustomerITAdmin = async (
  custResourceId,
  authStack,
  authProvider
) => {
  const userTenantMgtClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider.createOrgedAuthProvider?.()
  )
  const response =
    await userTenantMgtClient.getUserWithTenantDetails(custResourceId)
  return response.data
}

export const getAccountsData = async (
  authStack,
  authProvider,
  orgSelector
): Promise<any> => {
  const accountClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider.createOrgedAuthProvider?.()
  )
  const tenantResourceId = orgSelector.getOrgTenantId()
  let usrListResponse
  const maxResult = 0
  try {
    usrListResponse = await accountClient.getUserWithTenantList(
      tenantResourceId,
      maxResult
    )
  } catch (e) {
    console.log('Error loading account With Tenant List', e)
  }
  return usrListResponse
}

export const getIsTechnician = async (
  authStack,
  authProvider,
  permissionSet
) => {
  const userClient = new UserTenantMgtSvcClient(
    authStack,
    authProvider.createOrgedAuthProvider?.()
  )
  let isTechnician
  await userClient
    .getCurrentActiveUser()
    .then(({ status, data }) => {
      if (status === 200) {
        const techPermId = permissionSet.filter(
          (permId) => permId.label == 'Technician'
        )
        data.rolePermId === techPermId[0].rolePermId
          ? (isTechnician = true)
          : (isTechnician = false)
      } else {
        isTechnician = false
        return isTechnician
      }
    })
    .catch(() => {
      isTechnician = false
      return isTechnician
    })
  return isTechnician
}
