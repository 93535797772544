import React, { useCallback } from 'react'
import { MicroFrontend } from '@jarvis/react-mfe-component'
import { getProps } from '../../utils/commonMethods'

export const ThirdPartyIDPSettings = ({
  navigation,
  nodeId,
  mfe,
  basePath
}) => {
  const props = getProps()
  const SubMfe = useCallback(
    ({ component, navigation, nodeId, mfe, basePath, type }) => {
      return (
        <MicroFrontend
          disableContainerPadding
          {...props}
          {...{
            component,
            navigation,
            nodeId,
            mfe,
            basePath,
            type
          }}
        />
      )
    },
    [props]
  )

  return (
    <SubMfe
      type="third-party-idp-settings"
      props={props}
      navigation={navigation}
      nodeId={nodeId}
      mfe={mfe}
      basePath={basePath}
      component="@jarvis/react-ecp-third-party-idp-settings"
    />
  )
}
