import React, { useEffect, useState } from 'react'
import { Button, Search, IconFunnel } from '@veneer/core'
import FilterSidebar from '../../../../src/Shared-Components/FilterSidebar/FilterSidebar'
import { SearchContent } from './../styles'
import { getCustomerDetails } from '../../../../src/utils/api'
import { CustomerData } from '../../../../src/interfaces/customerDataInterface'
import Customers from '../Customers/Customers'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'

export const MainContent = ({
  isLoading,
  customers,
  writePermForSettings,
  showSettingsButtonFlag,
  setShowCustomerDetails,
  setShowCustomerSettings,
  setViewCustomer,
  setIsLoading,
  retriveCustomers,
  hasError
}) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const breadcrumbs = interfaces?.v1?.breadcrumbs
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider

  useEffect(() => {
    setShowCustomerDetails(false)
    setShowCustomerSettings(false)
    breadcrumbs?.remove('customer-details')
    breadcrumbs?.remove('customer-settings')
  })

  const [showFilterSidebar, setShowFilterSidebar] = useState<boolean>(false)
  const [searchItem, setSearchItem] = useState<string>('')
  const [searchItems, setSearchItems] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const retriveCustomerDetails = async (flag: boolean, referenceId: string) => {
    const response = await getCustomerDetails(
      referenceId,
      authStack,
      authProvider
    )
    setViewCustomer(response)
    setShowCustomerDetails(flag)
  }

  const setOpenSettings = (flag: boolean, customerData: CustomerData) => {
    setShowCustomerSettings(flag)
    setViewCustomer(customerData)
  }

  const handleUserFilter = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsLoading(true)
    setSearchItem(searchValue)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  const handlePerformFilter = (value1: string, value2: string) => {
    value2 === 'push' ? setSearchItems([...searchItems, value1]) : null
    value2 === 'pop'
      ? setSearchItems(searchItems.filter((item) => item !== value1))
      : null
    value2 === 'clearall' ? setSearchItems([]) : null
  }

  return (
    <>
      <>
        <SearchContent data-testid="search-content">
          <form
            id="customers-search-form"
            data-testid="customers-search-form"
            onSubmit={handleUserFilter}
          >
            <Search
              data-testid="customers-search-box"
              className="customers-searchBox"
              id="customers-table-name-filter"
              placeholder={t(
                'customer-management.tableHeader.search',
                'Search'
              )}
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
              onClear={() => setSearchItem('')}
              content={undefined}
              nonce={undefined}
              rel={undefined}
              rev={undefined}
            />
            <Button
              data-testid="customers-filter-button"
              className="customers-filter-button"
              appearance="tertiary"
              leadingIcon={<IconFunnel />}
              onClick={() => setShowFilterSidebar(true)}
            >
              {t('customer-management.addRetryButton.filter', 'Filter')}
            </Button>
          </form>
        </SearchContent>
        {showFilterSidebar && (
          <div
            data-testid="filter-side-bar-div"
            id="filter-side-bar-div"
          >
            <FilterSidebar
              data-testid="filter-side-bar"
              onclose={() => setShowFilterSidebar(false)}
              performFilter={(value1: string, value2: string) =>
                handlePerformFilter(value1, value2)
              }
              appliedFilter={searchItems}
            />
          </div>
        )}
        <Customers
          data-testid="customer-component"
          customers={customers}
          isLoading={isLoading}
          searchItem={searchItem}
          openCustomerDetails={(value) => retriveCustomerDetails(true, value)}
          openSettings={(value) => {
            setOpenSettings(true, value)
          }}
          writePermForSettings={writePermForSettings}
          showSettingsButtonFlag={showSettingsButtonFlag}
          searchItems={searchItems}
          retrieveCustomers={retriveCustomers}
          hasError={hasError}
        />
      </>
    </>
  )
}
