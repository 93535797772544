import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TableSortBy,
  ToastProvider,
  Table,
  TableColumns,
  PageSizeInterface
} from '@veneer/core'
import ContextualFooter from './contextualFooter'
import { CustomerList } from 'src/interfaces/customerDataInterface'
import Retrievei18nItems from '../../../utils/Retrievei18nItems'
import { ErrorWidget } from '@jarvis/react-portal-addons'
import {
  selectRow,
  filter,
  sort,
  handleMultipleUsers,
  selectionState,
  jsonParse
} from '../../../utils/commonMethods'
import {
  CustomerConstants,
  defaultOrderColumn,
  defaultpageSizeOptions,
  preferences
} from '../../../utils/constants'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'
import * as S from '../styles'

const Customers = ({
  hasError,
  retrieveCustomers,
  customers,
  searchItem,
  searchItems,
  openSettings,
  isLoading,
  openCustomerDetails,
  writePermForSettings,
  showSettingsButtonFlag
}) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  const createCustomerData = useCallback(
    (customerData: CustomerList[]) => {
      return customerData.map((customer, index) => ({
        ...customer,
        CustomerName: customer.accountName,
        name: (
          <>
            <S.ViewDetailsMaskStyle
              data-testid="details-mask"
              onClick={() => openCustomerDetails(customer.nodeId)}
            ></S.ViewDetailsMaskStyle>
            <div>{customer.accountName}</div>
          </>
        ),
        country: customer.regionId,
        uid: index,
        rowConfig: {
          selected: false
        }
      }))
    },
    [openCustomerDetails]
  )

  const [currentPage, setCurrentPage] = useState<number>(
    CustomerConstants.currentPage
  )
  const customerTableColumns = 'customerListColumns'
  const [customerData, setCustomerData] = useState<CustomerList[]>([])

  const [userPageData, setUserPageData] = useState<CustomerList[]>([])
  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(customerTableColumns), defaultOrderColumn)
  )
  const [totalItems, setTotalItems] = useState<number>(customers.length)
  const [pageSize, setPageSize] = useState<number>(CustomerConstants.pageSize)
  const [orderBy, setOrderBy] = useState<string>(CustomerConstants.name)
  const [orderType, setOrderType] = useState<string>(
    CustomerConstants.ascending
  )
  const selectedItems: CustomerList[] = customerData.filter(
    (data) => data[CustomerConstants.rowConfig][CustomerConstants.selected]
  )
  const numberOfSelectedItems: number = selectedItems.length
  const customerListColumns = [
    {
      id: 'name',
      label: t('customer-management.tableHeader.customerName', 'Customer Name'),
      required: true
    },
    {
      id: 'country',
      label: t(
        'customer-management.tableHeader.countryRegion',
        'Country/Region'
      )
    },
    {
      id: 'uid',
      label: 'uid',
      index: 'hidden'
    }
  ] as TableColumns[]

  const customNoItems = useMemo(() => {
    if (hasError) {
      return (
        <S.CustomNoITemsContainer data-testid="error-customers-list">
          <ErrorWidget
            onRetry={retrieveCustomers}
            message={t(
              'customer-management.customerMain.unableToloadData',
              ' Unable to Load Data'
            )}
            labelRetry={t('customer-management.customerMain.retry', 'Retry')}
          />
        </S.CustomNoITemsContainer>
      )
    } else if (searchItem !== '') {
      return (
        <S.CustomNoITemsContainer data-testid="error-empty-customers-list">
          <ErrorWidget
            message={t(
              'customer-management.customers.noResultsFound',
              'No Results Found'
            )}
          />
          <S.VeneerSpace2 />
          <p
            className="invalid-search-label"
            data-testid="invalid-search-label"
          >
            {t(
              'customer-management.customers.trySelectingOtherFilters',
              'Try selecting other filters or adjusting your search keywords'
            )}
          </p>
          <S.VeneerSpace2 />
        </S.CustomNoITemsContainer>
      )
    } else {
      return (
        <S.CustomNoITemsContainer data-testid="error-customers">
          <ErrorWidget
            message={t(
              'customer-management.customers.noCustomers',
              'No Customers Found'
            )}
          />
          <S.VeneerSpace2 />
          <S.InviteCustomer data-testid="invite-customers-label">
            {t(
              'customer-management.customers.inviteCustomer',
              'Invite customer for business'
            )}
          </S.InviteCustomer>
          <S.VeneerSpace2 />
        </S.CustomNoITemsContainer>
      )
    }
  }, [hasError, retrieveCustomers, searchItem, t])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (
    event: React.MouseEvent<HTMLInputElement>,
    option: PageSizeInterface
  ) => {
    event.preventDefault()
    setPageSize(option.value)
  }

  const handleSort = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    { id, type }: TableSortBy
  ) => {
    if (id === CustomerConstants.name) {
      id = CustomerConstants.customerName
    }
    if (id === CustomerConstants.country) {
      id = CustomerConstants.country
    }
    setOrderBy(id)
    setOrderType(type)
  }

  useEffect(() => {
    const filterData = filter(customerData, searchItem, searchItems)
    setTotalItems(filterData.length)
    const currentStartIndex = (currentPage - 1) * pageSize
    const startIndex =
      filterData.length > currentStartIndex ? currentStartIndex : 0
    const pageData: CustomerList[] = filterData.slice(
      startIndex,
      startIndex + pageSize
    )
    setUserPageData(pageData)
    setTimeout(() => {
      handlePageChange(filterData.length > pageSize ? currentPage : 1)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customers,
    isLoading,
    searchItem,
    customerData,
    // currentPage, // Disabled because it was causing page back & forth (JECP-516)
    pageSize,
    searchItems
  ])

  const setPaginationData = useCallback(() => {
    const startIndex = (currentPage - 1) * pageSize
    const pageData: CustomerList[] = filter(
      customerData,
      searchItem,
      searchItems
    ).slice(startIndex, startIndex + pageSize)
    setUserPageData(pageData)
  }, [currentPage, customerData, pageSize, searchItem, searchItems])

  useEffect(() => {
    const getCustomerData = createCustomerData(customers)
    sort(
      getCustomerData,
      CustomerConstants.customerName,
      CustomerConstants.ascending
    )
    setCustomerData(getCustomerData)
  }, [createCustomerData, customers, isLoading])

  useEffect(() => {
    setUserPageData(sort(customerData, orderBy, orderType))
    setPaginationData()
  }, [orderType, orderBy, customerData, setPaginationData])

  useEffect(() => {
    setPaginationData()
  }, [currentPage, pageSize, setPaginationData])

  const handleSelect = (event: React.ChangeEvent, index: number | string) => {
    setUserPageData(
      selectRow(customerData, event.target['checked'], index as number)
    )
    setPaginationData()
  }

  const handleSelectAllOrDeselectAll = (selected: boolean) => {
    handleMultipleUsers(customerData, selected)
    setPaginationData()
  }

  const handleSelectAllPageItems = (event: React.ChangeEvent) => {
    setUserPageData(handleMultipleUsers(userPageData, event.target['checked']))
  }
  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(customerTableColumns, JSON.stringify(newOrder))
  }

  return (
    <ToastProvider>
      <S.VeneerTable>
        <Table
          data-testid="customers-data-table"
          className="customer-table-br"
          columns={customerListColumns}
          columnReorder
          data={userPageData}
          i18n={Retrievei18nItems()}
          onSort={handleSort}
          preferences={{
            ...preferences,
            defaultOrder: defaultOrderColumn,
            order
          }}
          onColumnReorder={handleOnColumnReorder}
          loading={isLoading}
          loadingDataLength={3}
          pagination={{
            currentPage: currentPage,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            pageSize: pageSize,
            pageSizeOptions: defaultpageSizeOptions,
            popoverPlacement: 'top-start',
            totalItems
          }}
          rowSelector={'multiSelection'}
          onDeselectAllItems={() => handleSelectAllOrDeselectAll(false)}
          onSelect={handleSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          onSelectAllItems={() => handleSelectAllOrDeselectAll(true)}
          rowSelectAllState={selectionState(userPageData)}
          component={'table'}
          customNoItems={customNoItems}
        />
      </S.VeneerTable>
      {numberOfSelectedItems > 0 && (
        <ContextualFooter
          data-testid="contextual-footer"
          onCancel={() => {
            handleSelectAllOrDeselectAll(false)
          }}
          openSettings={() => openSettings(selectedItems[0])}
          numberOfSelectedItems={numberOfSelectedItems}
          selectedItems={selectedItems}
          writePermForSettings={writePermForSettings}
          showSettingsButtonFlag={showSettingsButtonFlag}
        />
      )}
    </ToastProvider>
  )
}

export default Customers
