import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: ${primitives.color.gray12};
  background: #f8f8f8;
  font-family: ${primitives.typography.family0} !important;
  padding: ${primitives.layout.size6}px;
`

export const CardStyle = styled.section`
  padding: 14px 10px 14px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  border-radius: ${primitives.layout.cornerRadius4}px;
  margin-bottom: ${primitives.layout.size7}px !important;
`
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const CardTitle = styled.div`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight4};
  margin-bottom: 14px;
  svg {
    width: 20px;
    height: 20px;
    margin: 0 0 ${primitives.layout.size1}px 6px;
    position: relative;
    vertical-align: middle;
    color: ${primitives.color.gray6};
  }
  #customer-details-tooltip {
    font-family: ${primitives.typography.family0}!important;
    font-size: ${primitives.typography.size1} !important;
    line-height: ${primitives.typography.lineHeight1} !important;
  }
`
export const CardTitleBottomHr = styled.div`
  height: 1px;
  position: relative;
  margin-left: -20px;
  margin-right: -10px;
  margin-bottom: 20px;
  background-color: #ebebeb;
`
export const TextBlockStyle = styled.div`
  display: flex;
  flex-direction: row;
`
export const TextItemStyle = styled.div`
  border-left: 3px solid #ebebeb;
  padding-left: ${primitives.layout.size2}px;
  width: 95px;
`

export const TextItemTitle = styled.div`
  color: ${primitives.color.gray6};
  font-size: ${primitives.typography.size0};
  line-height: ${primitives.typography.lineHeight1};
  text-transform: uppercase;
`
export const TextItem = styled.div`
  line-height: ${primitives.typography.lineHeight3};
  font-size: ${primitives.typography.size2};
  font-family: ${primitives.typography.family0};
`
export const BasicTextItemStyle = styled.div`
  display: block;
  margin-bottom: ${primitives.layout.size5}px;
`
export const BasicTextItemTitle = styled.div`
  color: ${primitives.color.gray6};
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
`
export const BasicTextItem = styled.div`
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
`
export const TitleCardStyle = styled.section`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  border-radius: ${primitives.layout.cornerRadius4}px;
  margin-bottom: ${primitives.layout.size7}px !important;
  :first-child,
  .vn-animated-height__wrapper-inner > div {
    padding: 10px;
    margin: 0;
    color: ${primitives.color.gray12};
  }
  div[role='button'] {
    padding: 14px ${primitives.layout.size5}px;
    height: 25px;
  }
`
export const TitleBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight3};
  h4 {
    font-family: ${primitives.typography.family0} !important;
    font-size: ${primitives.typography.size4} !important;
    line-height: ${primitives.typography.lineHeight4} !important;
    margin-top: 2px;
    flex-grow: 9;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    position: relative;
    vertical-align: middle;
    color: #027aae;
    margin-top: 2px;
  }
`
export const ActionButtonsStyle = styled.div`
  text-align: right;
  margin-top: -5px;
  button {
    height: 36px;
    margin: 0 5px 0 0;
    border-radius: ${primitives.layout.cornerRadius2}px;
  }
  .settingsBtn {
    width: 36px !important;
    height: 36px;
    margin: 1px ${primitives.layout.size5}px;
  }
  .editBtn {
    width: 66px !important;
    min-width: 66px !important;
  }
  .eclipseBtn {
    width: 36px !important;
    min-width: 36px !important;
    svg {
      margin-left: 0 !important;
    }
  }
`
export const EditButtonStyle = styled.div`
  flex-grow: 1;
  text-align: right;
  button {
    height: 30px;
    margin: 0;
  }
`
