/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonGroup, IconInfo, Accordion, Tooltip } from '@veneer/core'
import React, { useEffect, useState } from 'react'
import { CustomerList } from 'src/interfaces/customerDataInterface'
import {
  getCustomerDevices,
  getCustomerITAdmin,
  getAccountsData
} from '../../../utils/api'
import Loader from '../../../Shared-Components/Loader'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'
import {
  ActionButtonsStyle,
  BasicTextItem,
  BasicTextItemStyle,
  BasicTextItemTitle,
  CardHeader,
  CardStyle,
  CardTitle,
  CardTitleBottomHr,
  TextBlockStyle,
  TextItem,
  TextItemStyle,
  TextItemTitle,
  TitleBoxStyle,
  TitleCardStyle
} from '../CustomerDetails/styles'

const CustomerDetails = ({
  selectedCustomer,
  openSettings,
  writePermForSettings,
  showSettingsButtonFlag,
  setShowCustomerSettings,
  mockEvents
}: CustomerDetailsProps) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authProvider = interfaces?.v2.authProvider
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const breadcrumbs = interfaces?.v1?.breadcrumbs
  const events = interfaces?.v1?.events || mockEvents
  const orgSelector = interfaces?.v1.orgSelector

  useEffect(() => {
    setShowCustomerSettings(false)
    breadcrumbs?.remove('customer-settings')
    breadcrumbs?.remove('customer-details')
    breadcrumbs?.add({
      key: 'customer-details',
      text: `${t('customer-management.details', 'Details')}`,
      translationKey: undefined,
      url: undefined
    })
  })

  const [devicesCount, setDevicesCount] = useState<number>(0)
  const [ITAdminCount, setITAdminCount] = useState<number>(0)
  const [iTAdmins, setITAdmins] = useState<string>()
  const [orgProfileCount, setOrgProfileCount] = useState<number>(0)
  const [orgProfileData, setOrgProfileData] = useState<string>('')
  const [showDevicesLoader, setShowDevicesLoader] = useState<boolean>(false)
  const [showITAdminsLoader, setShowITAdminsLoader] = useState<boolean>(false)
  const [showOrgProfileLoader, setShowOrgProfileLoader] =
    useState<boolean>(false)

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', loadData)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', loadData)
    }
  }, [events])

  const getCustomerDevicesCount = async (id: string) => {
    setShowDevicesLoader(true)
    const devices = await getCustomerDevices(id, authStack, authProvider)
    setDevicesCount(devices)
    setShowDevicesLoader(false)
  }

  const getCustomerITAdminInfo = async (id: string) => {
    setShowITAdminsLoader(true)
    const response = await getCustomerITAdmin(id, authStack, authProvider)
    const response_array = response?.resourceList
    const response_length: number = response?.totalCount
    setITAdminCount(response_length)
    let orgData = ''
    response_array?.forEach((item) => {
      orgData += `${item.user.givenName} ${item.user.familyName}(${item.user.email.value}), `
    })
    const orgDataLen = orgData.length
    setITAdmins(orgData.substring(0, orgDataLen - 2))
    setShowITAdminsLoader(false)
  }

  const getOrganizationProfileInfoFromApi = async () => {
    setShowOrgProfileLoader(true)
    const response = await getAccountsData(authStack, authProvider, orgSelector)
    const response_array = response?.data?.resourceList
    const response_length: number = response_array?.length
    setOrgProfileCount(response_length)
    let orgData = ''
    response_array?.forEach((item) => {
      orgData += `${item.user.givenName} ${item.user.familyName}(${item.user.email.value}), `
    })
    const orgDataLen = orgData.length
    setOrgProfileData(orgData.substring(0, orgDataLen - 2))
    setShowOrgProfileLoader(false)
  }

  const loadData = () => {
    getCustomerDevicesCount(
      selectedCustomer?.resourceId || selectedCustomer?.nodeId
    )
    getCustomerITAdminInfo(
      selectedCustomer?.resourceId || selectedCustomer?.nodeId
    )
  }

  useEffect(() => {
    loadData()
    getOrganizationProfileInfoFromApi()
  }, [selectedCustomer?.resourceId || selectedCustomer?.nodeId])

  const renderActionButtons = () => {
    return (
      <ButtonGroup orientation="horizontal">
        <Button
          appearance="secondary"
          className="settingsBtn"
          data-testid="customer-detail-setting"
          onClick={() => openSettings()}
        >
          {' '}
          {t('customer-management.actionButtons.settings', 'Settings')}
        </Button>
      </ButtonGroup>
    )
  }

  const renderCustomerInfoHeader = () => {
    return (
      <>
        <TextBlockStyle>
          <TextItemStyle>
            <TextItemTitle>
              {t(
                'customer-management.customerInfoHeader.solutions',
                'Solutions'
              )}
            </TextItemTitle>
            <TextItem> 1 </TextItem>
          </TextItemStyle>
          <TextItemStyle>
            <TextItemTitle>
              {t('customer-management.customerInfoHeader.devices', 'Devices')}
            </TextItemTitle>
            {showDevicesLoader ? (
              <TextItem>
                <Loader />
              </TextItem>
            ) : (
              <TextItem>{devicesCount}</TextItem>
            )}
          </TextItemStyle>
        </TextBlockStyle>
      </>
    )
  }

  const renderCustomerInfo = () => {
    return (
      <TitleBoxStyle>
        <h4 data-testid="customer-detail-title">
          {selectedCustomer?.accountName}
        </h4>
        {writePermForSettings === true && showSettingsButtonFlag && (
          <ActionButtonsStyle> {renderActionButtons()}</ActionButtonsStyle>
        )}
      </TitleBoxStyle>
    )
  }

  const dataInitialis = [
    {
      content: renderCustomerInfoHeader(),
      expanded: true,
      header: {
        centralArea: renderCustomerInfo()
      }
    }
  ]

  const [data, setData] = useState(dataInitialis)

  const [hasEffect, setHasEffect] = useState<boolean>(false)

  const handleExpand = () => {
    const updatedData = [...dataInitialis]
    updatedData[0].expanded = true
    setData(updatedData)
    setHasEffect(true)
  }

  const handleCollapse = () => {
    const updatedData = [...dataInitialis]
    updatedData[0].expanded = false
    setData(updatedData)
    setHasEffect(true)
  }

  const renderCustomerHeader = () => {
    return (
      <TitleCardStyle>
        <Accordion
          items={hasEffect ? data : dataInitialis}
          onCollapse={handleCollapse}
          onExpand={handleExpand}
          data-testid="header-accordion"
        />
      </TitleCardStyle>
    )
  }

  const renderBasicInfo = () => {
    return (
      <CardStyle>
        <CardTitle data-testid="customer-detail-basic-information">
          {t(
            'customer-management.basicInfo.basicInformation',
            'Basic Information'
          )}
        </CardTitle>
        <CardTitleBottomHr />
        <BasicTextItemStyle>
          <BasicTextItemTitle data-testid="customer-detail-country-title">
            {t(
              'customer-management.tableHeader.countryRegion',
              'Country/Region'
            )}
          </BasicTextItemTitle>
          <BasicTextItem data-testid="customer-detail-country-value">
            {selectedCustomer?.regionId}
          </BasicTextItem>
        </BasicTextItemStyle>
        <BasicTextItemStyle>
          <BasicTextItemTitle data-testid="customer-detail-country-available">
            {t(
              'customer-management.basicInfo.availableCountriesRegions',
              'Available Countries/Regions for Business'
            )}{' '}
            ({selectedCustomer?.countrySet?.length || 0})
          </BasicTextItemTitle>
          <BasicTextItem data-testid="customer-detail-country-available-value">
            {selectedCustomer?.countrySet?.toString() || '-'}
          </BasicTextItem>
        </BasicTextItemStyle>
      </CardStyle>
    )
  }

  const renderAccountProfiles = () => {
    return (
      <CardStyle>
        <CardTitle data-testid="customer-detail-organization">
          {t(
            'customer-management.accountProfiles.organizationProfiles',
            'Organization Profiles'
          )}
          <Tooltip
            arrow
            content={t(
              'customer-management.tooltip.detailsOrgProfile',
              'Who are assigned to the Organization Profiles can be access this customer. Each of these users is given the original own role, providing them with permission to access this customer.'
            )}
            id="customer-details-tooltip"
            placement="trailing"
          >
            <IconInfo />
          </Tooltip>
        </CardTitle>
        <CardTitleBottomHr></CardTitleBottomHr>
        <BasicTextItemStyle>
          <BasicTextItemTitle data-testid="customer-detail-organization-title">
            {t(
              'customer-management.accountProfiles.assignedOrganization',
              ' Assigned Organization Profiles'
            )}{' '}
            ({orgProfileCount})
          </BasicTextItemTitle>
          {showOrgProfileLoader ? (
            <BasicTextItem data-testid="customer-detail-organization-value">
              <Loader />
            </BasicTextItem>
          ) : (
            <BasicTextItem data-testid="customer-detail-organization-value">
              {orgProfileData}
            </BasicTextItem>
          )}
        </BasicTextItemStyle>
      </CardStyle>
    )
  }

  const renderItAdminInfo = () => {
    return (
      <CardStyle>
        <CardTitle data-testid="customer-detail-it-admin">
          {t(
            'customer-management.itAdminInfo.customerITAdmin',
            'Customer IT Admin'
          )}
          <Tooltip
            arrow
            content={t(
              'customer-management.tooltip.detailsITAdmins',
              'Who are assigned to the Customer IT Admin can be access this customer. Each of these users is given the original own role, providing them with permission to access this customer.'
            )}
            id="customer-details-tooltip"
            placement="trailing"
          >
            <IconInfo />
          </Tooltip>
        </CardTitle>
        <CardTitleBottomHr></CardTitleBottomHr>
        <BasicTextItemStyle>
          <BasicTextItemTitle data-testid="customer-detail-it-admin-title">
            {t(
              'customer-management.itAdminInfo.assignedCustomerIT',
              'Assigned Customer IT Admin'
            )}{' '}
            ({ITAdminCount})
          </BasicTextItemTitle>
          {showITAdminsLoader ? (
            <BasicTextItem data-testid="customer-detail-it-admin-value">
              <Loader />
            </BasicTextItem>
          ) : (
            <BasicTextItem data-testid="customer-detail-it-admin-value">
              {iTAdmins}
            </BasicTextItem>
          )}
        </BasicTextItemStyle>
      </CardStyle>
    )
  }

  const renderIdpInfo = () => {
    return (
      <CardStyle>
        <CardHeader>
          <CardTitle data-testid="customer-detail-idp">
            {t('customer-management.idpInfo.domain', 'Domain')}
            <Tooltip
              arrow
              content={t(
                'customer-management.tooltip.detailsDomain',
                'The Domain provides the authentication method this customer signs in with. The user of this customer signs in with a defined authentication method.'
              )}
              id="tooltip"
              placement="trailing"
            >
              <IconInfo />
            </Tooltip>
          </CardTitle>
          {writePermForSettings === true && showSettingsButtonFlag && (
            <Button
              data-testid="customer-detail-idp-edit-button"
              appearance="tertiary"
              onClick={() => openSettings()}
            >
              {t('customer-management.idpInfo.edit', 'Edit')}
            </Button>
          )}
        </CardHeader>
        <CardTitleBottomHr></CardTitleBottomHr>
        <BasicTextItemStyle>
          <BasicTextItemTitle data-testid="customer-detail-idp-authentication">
            {t(
              'customer-management.idpInfo.authenticationMethod',
              ' Authentication Method'
            )}
          </BasicTextItemTitle>
          <BasicTextItem data-testid="customer-detail-idp-domain">
            {t(
              'customer-management.idpInfo.domainNotAvailable',
              'Domain info is not available from API response'
            )}
          </BasicTextItem>
        </BasicTextItemStyle>
      </CardStyle>
    )
  }

  return (
    <>
      {renderCustomerHeader()}
      {renderBasicInfo()}
      {renderAccountProfiles()}
      {renderItAdminInfo()}
      {renderIdpInfo()}
    </>
  )
}
export interface CustomerDetailsProps {
  selectedCustomer?: CustomerList
  openSettings: () => void
  writePermForSettings?: boolean
  showSettingsButtonFlag?: boolean
  setShowCustomerSettings?: (v: boolean) => void
  mockEvents?: {
    addEventListener: (e: string, f: () => void) => void
    removeEventListener: (e: string, f: () => void) => void
  }
}

export default CustomerDetails
